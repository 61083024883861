<template>
    <div class="sf-highlights">
        <div class="sf-highlights__wrapper">
            <div class="sf-highlights__title">
                <h2>{{ $t('latest') }}</h2>
            </div>

            <div class="sf-highlights__entries">
                <nuxt-link :to="$urlToPath(selectedEntry.url)">
                    <sf-image-stack
                        :images="imageStack"
                        :selected-image-index="selectedEntryIndex"
                        @image-hover="onImageHover"
                    >
                        <template
                            v-slot:badge="{imageIndex}"
                        >
                            <sf-date-badge
                                v-if="filteredEntries[imageIndex].entryType === 'event'"
                                :date="filteredEntries[imageIndex].fromDate"
                            />
                        </template>
                        <template v-slot:caption>
                            <h3>{{ selectedEntry.title }}</h3>
                        </template>
                    </sf-image-stack>
                </nuxt-link>
            </div>

            <ul>
                <li
                    v-for="(entry, index) in filteredEntries"
                    :key="index"
                    class="sf-highlights-list__title"
                >
                    <!--eslint-disable-->
                    <nuxt-link
                        :data-active="index === selectedEntryIndex"
                        :to="$urlToPath(entry.url)"
                        @mouseover.native="updateSelected(index)"
                    >
                        {{ entry.title | truncate(100) }}
                    </nuxt-link>
                    <!--eslint-enable-->
                </li>
            </ul>
            <div class="sf-highlights__more">
                <p>
                    {{ $t('more') }} <nuxt-link :to="localePath('news')">{{ $t('news') }}</nuxt-link> {{ $t('or') }} <nuxt-link :to="localePath('events')">{{ $t('agenda') }}</nuxt-link>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import SfImageStack from '~/patterns/molecules/image-stack/image-stack.vue';
import SfDateBadge from '~/patterns/atoms/date-badge/date-badge.vue';

export default {
    components: {
        SfImageStack,
        SfDateBadge
    },

    props: {
        entries: {
            type: Array,
            default: null
        }
    },

    data() {
        return {
            selectedEntryIndex: 0
        };
    },

    computed: {
        selectedEntry() {
            return this.entries[this.selectedEntryIndex];
        },
        filteredEntries() {
            return this.entries ? this.entries.slice(0, 3) : [];
        },
        imageStack() {
            return this.filteredEntries.map((entry) => {
                return entry.featuredImage[0];
            });
        }
    },

    created() {
        this.shuffle(this.filteredEntries);
    },

    methods: {
        updateSelected(selectedEntryIndex) {
            this.selectedEntryIndex = selectedEntryIndex;
        },
        onImageHover(imageIndex) {
            this.updateSelected(imageIndex);
        },
        shuffle(array) {
            let currentIndex = array.length, randomIndex;
            while (currentIndex !== 0) {
                randomIndex = Math.floor(Math.random() * currentIndex);
                currentIndex--;
                [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
            }
            return array;
        }
    }
};
</script>

<style lang="less" src="./highlights.less"></style>

<i18n>
{
    "nl": {
        "latest": "actueel",
        "more": "meer",
        "news": "nieuws",
        "or": "of",
        "agenda": "agenda"
    },
    "en": {
        "latest": "latest news",
        "more": "more",
        "news": "news",
        "or": "or",
        "agenda": "agenda"
    }
}
</i18n>
