<template>
    <sf-basic-template
        v-if="entry"
        class="template--spacing-top"
    >
        <sf-section layout="home-intro">
            <template #aside>
                <sf-main-menu-list
                    :index-tabs="false"
                    aria-hidden="true"
                />
            </template>

            <template #default>
                <sf-highlights
                    v-if="entry.featuredEntries && entry.featuredEntries.length > 0"
                    :entries="entry.featuredEntries"
                />
            </template>
        </sf-section>

        <sf-section>
            <sf-discover-stream
                class="discover-stream--mirrored"
                :entries="entries"
                :global-entries="entry.streamGlobalEntries"
                :order="entry.streamBehaviour"
            />
        </sf-section>
    </sf-basic-template>
</template>

<script>
import CollapseMixin from '~/mixins/collapse';
import { NavigationVisibleOnScroll } from '~/mixins/navigation';

import SfBasicTemplate from '~/patterns/templates/basic.vue';
import SfSection from '~/patterns/organisms/section/section';
import SfDiscoverStream from '~/patterns/organisms/discover-stream/discover-stream';
import SfMainMenuList from '~/patterns/molecules/menu-list/presets/main-menu.vue';
import SfHighlights from '~/patterns/organisms/highlights/highlights.vue';

export default {
    components: {
        SfBasicTemplate,
        SfSection,
        SfDiscoverStream,
        SfHighlights,
        SfMainMenuList
    },

    mixins: [
        CollapseMixin,
        NavigationVisibleOnScroll
    ],

    async asyncData(context) {
        const data = { };
        const entryResponse = await context.store.dispatch('query/home');

        if (!entryResponse || !entryResponse.data || !entryResponse.data.entry) {
            return context.error({ statusCode: 404, message: 'error.message.404' });
        }

        data.entry = entryResponse.data.entry;

        const entriesResponse = await context.store.dispatch('query/discoverEntries', {
            limit: 10,
            showOnHome: true
        });

        if (entriesResponse && entriesResponse.data && entriesResponse.data.entries) {
            data.entries = entriesResponse.data.entries;
        }

        return data;
    },

    data() {
        return {
            entry: null,
            entries: [],
            lateCollapse: true
        };
    },

    head() {
        return this.$seo.getMetaData(this.entry.seo);
    }
};
</script>
